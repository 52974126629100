<template>
  <div class="flex justify-content-start staff-filters align-item-center">
    <custom-input
        :delay="800"
        name="staff-passings-sort"
        label="Имя"
        :inputWidth="100"
        cleanable
        v-model="searchParams.nameField"
    />
    <custom-input
        :delay="800"
        name="staff-passings-sort"
        label="Email"
        :inputWidth="100"
        cleanable
        v-model="searchParams.emailField"
    />
    <custom-select-2
        v-model="searchParams.roleField"
        name="role"
        label="Роль"
        :options="[
        'Не выбрано',
        'Суперадмин',
        'Администратор',
        'Сотрудник',
        'Частное лицо',
      ]"
    />
    <button @click="clearSearch" class="btn btn-w clear-btn">Очистить</button>
  </div>
  <create-group-row :checkedList="checkedList"/>
  <div class="flex justify-content-start sort-line align-item-center">
    <button class="btn clear-sort" @click="changeSort('')">
      <img src="@/assets/images/icons/close.svg"/>
    </button>
    <button
        :class="{ active: sortType == 'ASC_name' || sortType == 'DESC_name' }"
        class="btn sort-text"
        @click="
        () =>
          sortType === 'ASC_name'
            ? changeSort('DESC_name')
            : changeSort('ASC_name')
      "
    >
      По алфавиту
      <i v-if="sortType === 'ASC_name'" class="fal fa-arrow-down-a-z"></i>
      <i v-if="sortType === 'DESC_name'" class="fal fa-arrow-up-a-z"></i>
    </button>
    <button
        :class="{
        active: sortType == 'DESC_created_at' || sortType == 'ASC_created_at',
      }"
        class="btn sort-text"
        @click="
        () =>
          sortType === 'ASC_created_at'
            ? changeSort('ASC_created_at')
            : changeSort('DESC_created_at')
      "
    >
      По дате регистрации
      <i
          v-if="sortType === 'DESC_created_at'"
          class="fal fa-arrow-down-1-9"
      ></i>
      <i v-if="sortType === 'ASC_created_at'" class="fal fa-arrow-up-1-9"></i>
    </button>
    <button
        @click="() => changeSort('online_from')"
        :class="{ active: sortType == 'online_from' }"
        class="btn sort-text"
    >
      По дате авторизации
    </button>
  </div>
  <div class="justify-content-start mobile-sort">
    <Popper placement="bottom" arrow class="action-popper sort-popper">
      <button class="btn btn-w sort-btn"></button>
      <template #content>
        <ul class="sort_list">
          <li
              @click="() => changeSort('ASC_name')"
              class="sort-value"
              :class="{ active: sortType == 'ASC_name' }"
          >
            По алфавиту <i class="fal fa-arrow-down-a-z"></i>
          </li>
          <li
              @click="() => changeSort('DESC_name')"
              class="sort-value"
              :class="{ active: sortType == 'DESC_name' }"
          >
            По алфавиту <i class="fal fa-arrow-up-a-z"></i>
          </li>
          <li
              @click="() => changeSort('ASC_created_at')"
              class="sort-value"
              :class="{ active: sortType == 'ASC_created_at' }"
          >
            Сначала новые пользователи <i class="fal fa-arrow-down-1-9"></i>
          </li>
          <li
              @click="() => changeSort('DESC_created_at')"
              class="sort-value"
              :class="{ active: sortType == 'DESC_created_at' }"
          >
            Сначала старые пользователи <i class="fal fa-arrow-up-1-9"></i>
          </li>
          <li
              @click="() => changeSort('online_from')"
              class="sort-value"
              :class="{ active: sortType == 'online_from' }"
          >
            По дате последней авторизации
          </li>
        </ul>
      </template>
    </Popper>
  </div>
  <div v-if="!loadPreloader && rowData.length">
    <base-table
        :rows=rowData :rowSelect=onRowSelected
        :columns="staffTableFields" :checkedList=checkedList
    />

    <table-pagination
        :totalPages="15"
        :countOnPage="countOnPage"
        :total="totalUsers"
        :count="users?.length"
        :startPage="currentPage"
        @changePage="changePage"
    />
  </div>
  <div v-if="loadPreloader" class="table-preloader">
    <preloader/>
  </div>
  <div v-if="!loadPreloader && !rowData.length">
    <p class="no_results">
      Нет результатов
    </p>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import {useRoute} from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CreateGroupRow from "@/feature/group-control/CreateGroupRow.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "staff-table",
  components: {
    BaseTable,
    Preloader,
    CustomInput,
    CustomSelect2,
    CreateGroupRow,
    TablePagination,
    CustomSelect,
  },
  setup() {
    const store = useStore(),
        route = useRoute(),
        checkedList = ref([]),
        currentPage = ref(1);

    const loadPreloader = ref(false);
    const sortType = ref("");

    const staffTableFields = [
      {
        field: "name",
        headerName: "Имя",
        link: true,
        dest: 'staff',
        res: "edit",
      },
      {
        field: "email",
        headerName: "Email"
      },
      {
        field: "groups",
        headerName: "Группы",
      },
      {
        field: "createdAt",
        headerName: "Дата регистрации",
      },
      {
        field: "onlineFrom",
        headerName: "Дата последней авторизации",
      },
      {
        field: "role",
        headerName: "Роль",
      },
      {
        field: "affiliation",
        headerName: "Принадлежность",
      },
      {
        field: "eiToken",
        headerName: "Зайти под учётную запись",
      },
      {
        field: "edit",
        headerName: "",
        dest: "staff",
        res: "edit"
      },
    ];
    const countOnPage = ref(15),
        isPreloader = ref(false),
        searchParams = reactive({
          nameField: "",
          emailField: "",
          roleField: "Не выбрано",
        }),
        profile = computed(() => store.state.profile.profile),
        roleOptions = computed(() =>
            !profile.value || profile.value.role !== "superadmin"
                ? ["Не выбрано", "Администратор", "Сотрудник"]
                : [
                  "Не выбрано",
                  "Суперадмин",
                  "Администратор",
                  "Сотрудник",
                  "Частное лицо",
                ]
        );
    const users = computed(() => store.state.users.users),
        totalUsers = computed(() => store.state.users.totalUsers),
        rowData = computed(() => {
          return users.value.map((staff) => {
            return {
              id: staff.id,
              name: staff.name,
              email: staff.email,
              photo: staff.image,
              onlineFrom: staff.online_from,
              createdAt: staff.created_at,
              role: staff.role_russian,
              affiliation: staff.affiliation,
              groups: staff.groups,
              edit: staff.id,
            };
          })
        });
    const isSearching = computed(
        () =>
            searchParams.nameField.length >= 3 ||
            searchParams.emailField.length >= 3 ||
            searchParams.roleField != "Не выбрано"
    );

    onBeforeMount(() => {
      loadPreloader.value = true;
      store.dispatch("users/getUsers", {
        perPage: 15,
        page: currentPage.value,
      }).then(() => loadPreloader.value = false);
    });

    const onRowSelected = (id, selectAll) => {
      const isInclude = checkedList.value.includes(id);
      if (id && isInclude) {

        if (!selectAll) {
          checkedList.value = checkedList.value.filter(
              (currentId) => currentId !== id
          );
        }
      } else {
        checkedList.value.push(id);
      }
    };

    const usersRequest = () => {
      loadPreloader.value = true;
      store
          .dispatch("users/getUsers", {
            perPage: 15,
            page: currentPage.value,
            sort: sortType.value,
          })
          .finally(() => (loadPreloader.value = false));
    };

    const searchRequest = () => {
      store
          .dispatch("users/getUsersBySearch", {
            name: searchParams.nameField,
            email: searchParams.emailField,
            perPage: 15,
            page: currentPage.value,
            role:
                searchParams.roleField !== "Не выбрано"
                    ? searchParams.roleField
                    : "",
          })
          .finally(() => (loadPreloader.value = false));
    };

    const changePage = (page) => {
      if (page !== currentPage.value) {
        currentPage.value = page;
        loadPreloader.value = true;
        if (!isSearching.value) {
          usersRequest();
          return;
        }
        searchRequest();
      }
    };

    const changeSort = (type) => {
      sortType.value === type ? (sortType.value = "") : (sortType.value = type);
      clearSearch();
      usersRequest();
    };

    const clearSearch = () => {
      searchParams.roleField = "Не выбрано";
      searchParams.nameField = "";
      searchParams.emailField = "";
    };

    watch(searchParams, () => {
      loadPreloader.value = true;
      currentPage.value = 1;
      if (isSearching.value) {
        sortType.value = "";
        searchRequest();
        return;
      }
      usersRequest();
    });

    return {
      route,
      countOnPage,
      isPreloader,
      users,
      searchParams,
      roleOptions,
      checkedList,
      onRowSelected,
      totalUsers,
      changePage,
      loadPreloader,
      clearSearch,
      sortType,
      changeSort,
      staffTableFields,
      rowData,
      currentPage
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  min-width: 350px;
}

.clear-btn {
  margin-left: 15px;
}

.no_results {
  padding: 40px 10px;
}

.sort-btn {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 17px;

  &::before {
    content: "\f161";
    font-family: "Font Awesome 5 Pro";
    font-size: 17px;
  }
}

.mobile-sort {
  display: none;
}

.sort_list {
  padding-right: 10px;
}

.sort-value {
  padding-left: 40px;
  position: relative;

  &.active::before {
    content: "\f00c";
    position: absolute;
    left: 15px;
    top: 6px;
    font-family: "Font Awesome 5 Pro";
  }
}

.sort-line {
  border-bottom: 1px solid var(--cream-color);
  padding-bottom: 10px;
  margin-top: 15px;

  .clear-sort {
    padding: 0;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .sort-text.active {
    font-weight: 500;
    color: var(--main-color);
  }
}

@media (max-width: 1540px) {
  .staff-filters {
    flex-direction: column;
  }

  .clear-btn {
    align-self: flex-end;
    margin-top: 15px;
    margin-right: 15px;
  }
  .form-group {
    min-width: 100%;
  }
}

@media (max-width: 970px) {
  .sort-line {
    display: none;
  }
  .mobile-sort {
    display: flex;
  }

  .staff-filters {
    top: 0;
    position: sticky;
    padding: 10px 0;
    background-color: #ffffff;
    z-index: 2;
  }
}
</style>
