<template>
  <div class="view-container">
    <div class="card staff-table">
      <div class="card-header card-header-divider">
        <div class="card-title">
          {{ getRight("usersList") ? "Пользователи" : "Сотрудники" }}
        </div>
        <div class="tools">
          <div class="btn btn-accent" @click="showLoadForm = !showLoadForm">
            Загрузить список сотрудников в формате excel
          </div>
          <router-link to="/staff/edit" class="btn btn-accent add_user_btn">
            {{
              getRight("usersList")
                ? "Добавить пользователя"
                : "Добавить сотрудника"
            }}
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <excel-load-row v-if="showLoadForm" class="my-10" />
        <staff-table />
      </div>
    </div>
  </div>
</template>

<script>
import ExcelLoadRow from "@/feature/users/ExcelLoadRow.vue";
import StaffTable from "@/feature/users/StaffTable.vue";
import { ref } from "@vue/reactivity";
import useCheckRights from "../../mixins/useCheckRights";

export default {
  components: { StaffTable, ExcelLoadRow },
  name: "staff-page",
  setup() {
    const showLoadForm = ref(false);
    const { getRight } = useCheckRights();

    return {
      showLoadForm,
      getRight,
    };
  },
};
</script>

<style>
.btn-accent {
  margin-left: 15px;
}
.tools .add_user_btn {
  line-height: inherit;
}
</style>
